.address-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.address-block {
  color: #000;
  font-size: 1.2em;
  font-weight: 200;
  border: solid 2px $fetch-gray-2;
  border-radius: 4px;
  box-shadow: 7px 7px 0 0 rgba(51, 101, 126, 0.1);
  -webkit-box-shadow: 7px 7px 0 0 rgba(51, 101, 126, 0.1);
  display: inline-block;
  padding: 20px 30px;
  justify-content: center;
  font-family: serif;
}

.shipping-address-card {
  .card-body {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  .stats {
    margin-top: 20px;
    text-align: center;
    font-style: italic;
  }
}
