@import "../../fetch/colors.scss";

$fds-red-600: map-get(map-get($colors, $key: red-palette), 600);

.corner-highlight {
  background-image: linear-gradient(
    225deg,
    $fds-red-600,
    $fds-red-600 10px,
    transparent 10px,
    transparent
  );
}

@media screen and (max-width: 500px) {
  .UserProfileController__newWorld {
    .card {
      padding: 1.5rem;
    }
  }
}
@media screen and (min-width: 501px) {
  .UserProfileController__newWorld {
    .card {
      padding: 3rem;
    }
  }
}
