@import "text";
@import "fade-in-animation";
@import "components/FetchNotice";
@import "components/PackageDetailsCard";
@import "components/PackageDetailsCardList";
@import "components/OverSizePackageDetailsCard";
@import "components/UseOurMobileAppModal.scss";
@import "components/AddPaymentTransactionModal.scss";
@import "components/WindowPayHistoryModal.scss";
@import "components/AlertModal.scss";
@import "components/DailyPackageGroupCard.scss";
@import "components/PackagePickupRequestedCard.scss";
@import "components/ResidentPackageGroupCard.scss";
@import "components/StorageContainerDetailsModal.scss";
@import "src/views/pages/Market/views/ProductTile/ProductTile.scss";

// fetch-design
@import "../fetch-design/components/ScheduleCard.scss";
@import "../fetch-design/components/DateBoxRow.scss";
@import "../fetch-design/components/Tabs.scss";
@import "../fetch-design/components/ModalEditDefaultPay.scss";
@import "../fetch-design/components/MenuButton.scss";
@import "../fetch-design/components/FetchDesignFormInputField.scss";
@import "../fetch-design/components/FetchDesignIconButton.scss";
@import "../fetch-design/components/Icons.scss";
@import "../fetch-design/components/Card.scss";
@import "../fetch-design/components/SlideShow.scss";
@import "../fetch-design/components/ResidentPackageGroupCardBody.scss";
@import "general.scss";

// Pages
@import "../fetch-design/pages/ResidentOnboardingPage.scss";

.ReactTable.with-filters .rt-noData {
  top: 128px;
}

.veryLightSuccessBackground {
  background-color: lighten($bg-success, 5%);
}
.veryLightWarningBackground {
  background-color: lighten($bg-warning, 5%);
}
.veryLightDangerBackground {
  background-color: lighten($bg-danger, 5%);
}
.veryLightGreyBackground {
  background-color: lighten($fetch-gray-3, 5%);
}
.veryLightPrimaryBackground {
  background-color: lighten($bg-primary, 5%);
}

pre > code[class*="language-jsx"],
pre > code[class*="language-jsx"] span {
  font-size: 12px;
}

.tooltip {
  z-index: 9999 !important;
}

.cursor-na {
  cursor: not-allowed;
}
