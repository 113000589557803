.fetch-modals__useOurMobileAppModal{
    .card-title{
        font-size: 1.5rem;
    }
}

.fetch-modals__iconContainer{
    margin-top: .9rem;
    margin-bottom: .9rem;
    display: flex;
    justify-content: center;
}

.fetch-modals__appleAppStoreIcon{
    a {
        display: inline-block;
        overflow: hidden;
        border-radius: 13px;
        width: 15rem;
        height: 4rem;
    }
    img {
        border-radius: 13px;
        width: 15rem;
        height: 4rem;
    }
}

.fetch-modals__googlePlayStoreIcon{
    img {
        width: 14rem;
    }
}
.fetch-modals__fetchDeliveryPartnerImg {
    width: 14rem;
    justify-content: center;
}