$colors: (
  neutral-palette: (
    100: #f1f2f2,
    200: #d8dadd,
    300: #bfc3c8,
    400: #a6abb2,
    500: #8d939d,
    600: #777d88,
    700: #606874,
    800: #4a525f,
    900: #333c4a,
    "black": #0a0c0f,
    "white": #ffffff,
  ),
  blue-palette: (
    100: #deeffa,
    200: #a7dbf0,
    300: #6fc8e6,
    400: #38b4dc,
    500: #00a0d2,
    600: #0084ae,
    700: #00678a,
    800: #004a66,
    900: #002e42,
    "midnight": #00303f,
  ),
  green-palette: (
    100: #d4ede6,
    200: #aadbcd,
    300: #7fcab4,
    400: #55b99b,
    500: #2aa782,
    600: #228b6a,
    700: #1a6f52,
  ),
  purple-palette: (
    100: #ebe7f1,
    200: #d7cfe3,
    300: #c3b7d5,
    400: #af9fc7,
    500: #9b87b9,
    600: #806a9e,
    700: #664c83,
  ),
  red-palette: (
    100: #fce1de,
    200: #f9c3bd,
    300: #f7a69d,
    400: #f4887c,
    500: #f16a5b,
    600: #d3564a,
    700: #b44339,
  ),
  yellow-palette: (
    100: #fdf6dd,
    200: #feecb6,
    300: #fee190,
    400: #ffd769,
    500: #ffcc42,
    600: #dfa332,
    700: #c07a21,
  ),
);

$fds-dark-bg-text-color: map-get(map-get($colors, "neutral-palette"), "black");
$fds-light-bg-text-color: map-get(map-get($colors, "neutral-palette"), "white");

// checks the lightness of a base color ($color)
// and returns either the $light color if the lightness is < 55%
// or $dark color if the lightness is > 55%
@function contrast-color($color, $dark, $light) {
  @return if(lightness($color) < 55%, $light, $dark);
}

@mixin colorizor($color, $gamma) {
  color: map-get(map-get($colors, $color), $gamma) !important;
}

@mixin bg-colorizor($color, $gamma) {
  background-color: map-get(map-get($colors, $color), $gamma) !important;
}

@mixin text-colorizor($color, $gamma) {
  color: contrast-color(
    map-get(map-get($colors, $color), $gamma),
    $fds-dark-bg-text-color,
    $fds-light-bg-text-color
  );
}

@mixin border-colorizor($color, $gamma) {
  border: solid 1px map-get(map-get($colors, $color), $gamma);
}

@mixin border-top-colorizor($color, $gamma) {
  border-top: solid 1px map-get(map-get($colors, $color), $gamma) !important;
}
@mixin border-right-colorizor($color, $gamma) {
  border-right: solid 1px map-get(map-get($colors, $color), $gamma) !important;
}
@mixin border-bottom-colorizor($color, $gamma) {
  border-bottom: solid 1px map-get(map-get($colors, $color), $gamma) !important;
}
@mixin border-left-colorizor($color, $gamma) {
  border-left: solid 1px map-get(map-get($colors, $color), $gamma) !important;
}

@mixin process-color($color) {
  $colorKeys: map-keys(
    $map: map-get($colors, "#{$color}-palette"),
  );

  @each $key in $colorKeys {
    .fds-text-#{$color}-#{$key},
    .fds-color-#{$color}-#{$key} {
      @include colorizor("#{$color}-palette", $key);
    }
    .fds-text-hover-#{$color}-#{$key}:hover,
    .btn.fds-text-hover-#{$color}-#{$key}:hover {
      @include colorizor("#{$color}-palette", $key);
    }
    .fds-text-disabled-#{$color}-#{$key}:disabled,
    .btn.fds-text-disabled-#{$color}-#{$key}:disabled {
      @include colorizor("#{$color}-palette", $key);
    }
    .fds-bg-#{$color}-#{$key} {
      @include bg-colorizor("#{$color}-palette", $key);
    }
    .fds-bg-#{$color}-#{$key}.fds-text-invert {
      @include bg-colorizor("#{$color}-palette", $key);
      @include text-colorizor("#{$color}-palette", $key);
    }
    .fds-bg-hover-#{$color}-#{$key}:hover,
    .btn.fds-bg-hover-#{$color}-#{$key}:hover {
      @include bg-colorizor("#{$color}-palette", $key);
    }
    .fds-bg-disabled-#{$color}-#{$key}:disabled,
    .btn.fds-bg-disabled-#{$color}-#{$key}:disabled {
      @include bg-colorizor("#{$color}-palette", $key);
    }
    .fds-border-#{$color}-#{$key},
    .btn.fds-border-#{$color}-#{$key} {
      @include border-colorizor("#{$color}-palette", $key);
    }
    .fds-border-top-#{$color}-#{$key} {
      @include border-top-colorizor("#{$color}-palette", $key);
    }
    .fds-border-right-#{$color}-#{$key} {
      @include border-right-colorizor("#{$color}-palette", $key);
    }
    .fds-border-bottom-#{$color}-#{$key} {
      @include border-bottom-colorizor("#{$color}-palette", $key);
    }
    .fds-border-left-#{$color}-#{$key} {
      @include border-left-colorizor("#{$color}-palette", $key);
    }
    .fds-border-hover-#{$color}-#{$key}:hover,
    .btn.fds-border-hover-#{$color}-#{$key}:hover {
      @include border-colorizor("#{$color}-palette", $key);
    }
    .fds-border-disabled-#{$color}-#{$key}:disabled,
    .btn.fds-border-disabled-#{$color}-#{$key}:disabled {
      @include border-colorizor("#{$color}-palette", $key);
    }
  }
}

// Icon Themes
.is-preferred-icon-theme {
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: #ffffff;
  --fa-secondary-color: #00a0d2;
}

@include process-color("neutral");
@include process-color("blue");
@include process-color("green");
@include process-color("yellow");
@include process-color("red");
@include process-color("purple");
