.PackageDetailsCard {
  background: #f7f7f7;
  border-radius: 20px;
  margin: 10px 0 0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);

  .PackageDetailsCard__upperCardHalf {
    border-bottom: solid 1px $fetch-gray-3;

    .PackageDetailsCard__upperCardHalfInnerContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 30px;

      .PackageDetailsCard__logo {
        width: 40%;
        font-size: 1.5em;
        color: $fetch-gray;
        font-weight: normal;
      }

      .PackageDetailsCard__carrier {
        color: $fetch-gray;
        align-self: center;
      }
    }

    &.clickable {
      cursor: pointer;
    }
  }

  .PackageDetailsCard__lowerCardHalf {
    padding: 15px 30px;
    font-size: 0.95em;

    .PackageDetailsCard__carrierLabel a {
      color: #343a40;
    }
  }
}
