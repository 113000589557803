.field-rows-section {
  .field-row {
    display: flex;
    flex: 1;

    .field-rows-field-container {
      width: 100%;
      padding: 5px;
    }
  }
}
