.fetch-FetchNotice {
  display: flex;
  flex-direction: column;
  border: 1px solid $fetch-yellow;
  border-radius: 3px;

  .fetch-FetchNotice__title {
    color: white;
    background-color: $fetch-yellow;
    padding: 5px;
  }

  .fetch-FetchNotice__body {
    padding: 5px 10px;
  }
}
