.express-schedule-card {
  .card-body {
    position: relative;
  }
  .disabled-overlay {
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    text-align: center;
    color: $fetch-dark-blue;
  }

  p {
    margin-top: 20px;
  }
}
.ExpressScheduleOptionForm__windowSelectionContainer {
  button.btn {
    margin: 5px 20px !important;
    padding: 5px;
  }
}