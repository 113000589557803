@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700");
$fontPath: "~../font/";
@font-face {
  font-family: "KohinoorBangla Bold";
  src: url("#{$fontPath}KohinoorBangla-Bold.woff2") format("woff2"),
    url("#{$fontPath}KohinoorBangla-Bold.woff") format("woff"),
    url("#{$fontPath}KohinoorBangla-Bold.ttf") format("truetype"),
    url("#{$fontPath}KohinoorBangla-Bold.svg#KohinoorBangla-Bold#kohinoorbangla_bold")
      format("svg");
  font-display: auto;
}
@font-face {
  font-family: "KohinoorBangla Regular";
  src: url("#{$fontPath}KohinoorBangla-Regular.woff2") format("woff2"),
    url("#{$fontPath}KohinoorBangla-Regular.woff") format("woff"),
    url("#{$fontPath}KohinoorBangla-Regular.ttf") format("truetype"),
    url("#{$fontPath}KohinoorBangla-Regular.svg#KohinoorBangla-Regular#kohinoorbangla_regular")
      format("svg");
  font-display: auto;
}
