.PackageDetailsCardList__packageList {
  position: relative;
}
.PackageDetailsCardList__expandControl {
  color: white;
  text-decoration: underline;
  font-weight: 600;
}

.modal-body
  > .PackageDetailsCardList
  > .expandCollapse
  > .PackageDetailsCardList__expandControl {
  color: $fds-blue-midnight;
}
.modal-body > .PackageDetailsCardList {
  margin: 40px 0 20px;
}

.PackageDetailsCardList {
  margin: 20px 0 20px;
  div.expandCollapse {
    filter: contrast(125%);
  }
  h2 {
    color: #fff;
  }
  .PackageDetailsCardList__packageList {
    margin-bottom: 10px;
  }
  &.collapsed {
    .PackageDetailsCard:nth-child(1) {
      position: relative;
      z-index: 3;
    }
    .PackageDetailsCard:nth-child(2) {
      position: absolute;
      top: 0;
      bottom: -6px;
      left: 25px;
      right: 25px;
      background: $fds-neutral-100;
      z-index: 2;
      > * {
        display: none;
      }
    }
    .PackageDetailsCard:nth-child(3) {
      position: absolute;
      top: 0;
      bottom: -10px;
      left: 50px;
      right: 50px;
      background: $fds-neutral-100;
      > * {
        display: none;
      }
    }
    .PackageDetailsCard:nth-child(n + 4) {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
    /* handle additional margin for absolutely positioned background cards */
    .PackageDetailsCardList__packageList {
      /* handle max margin for 3 or more packages */
      margin-bottom: 16px;
    }
    &.children-2 {
      .PackageDetailsCardList__packageList {
        margin-bottom: 12px;
      }
    }
    &.children-1 {
      .PackageDetailsCardList__packageList {
        margin-bottom: 6px;
      }
    }
  }
}
