.fds-modal-wrapper.windowPayHistoryModal {
  .modal-content {
    /* Allow content to expand since larger than normal modal content */
    width: auto;
    max-width: none;
    min-width: 720px;
  }
}

.surge-indicator-arrow {
  width: 14px;
}
.publish-text {
  margin-left: 22px;
}
