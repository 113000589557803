.field-action {
  align-items: center;

  .btn {
    margin: 0 15px;
  }
}

.user-attribute-modal-text-padding {
  padding-bottom: 15px;
}
