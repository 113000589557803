@import "../../fetch/colors.scss";

$fds-neutral-500: map-get(map-get($colors, $key: neutral-palette), 500);
$fds-neutral-600: map-get(map-get($colors, $key: neutral-palette), 600);
$fds-neutral-900: map-get(map-get($colors, $key: neutral-palette), 900);

.ScheduleCard {
    // This becomes .ScheduleCard__container
    @at-root #{&}__container {
        display: flex;
        flex-direction: column;
        padding: 7px 10px;
        width: 100%;
        min-height: 126.5px;
        border: 1px solid $fds-neutral-500;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 10px;
    }

    @at-root #{&}__section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;  
        &.top {
            margin-bottom: 8px;
        }
        &.middle{
            margin-bottom: 10px;
            &.filled-ratio{
                display: flex;
            }
        }
        &.bottom{
            justify-content: flex-end;
            &.unintialized{
                justify-content: end 
            }
        }
    }
    
    @at-root #{&}__uninitializedButton {
        margin-top: 8px;
        height: 38px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    @at-root #{&}__text {
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        color: $fds-neutral-900;
    }
    @at-root #{&}__oversizeText {
        color: $fds-neutral-900;
        font-weight: 600;
        font-size: 11px;
        padding: 4px 0px;   
    }
}

.BlockStatusIndicator{
    @at-root #{&}__label {
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 16px;
    }
}

.BlockTypeRatio{
    @at-root #{&}__text {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $fds-neutral-600;
    }
}

.FilledRatio{
    @at-root #{&}__ratioBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 8px;
        gap: 8px;
        width: 69px;
        height: 37px;
        font-weight: 700;
        font-size: 20px;
        color: $fds-neutral-900;
        &.editing{
            border: 1px solid;      
        }
    }
}