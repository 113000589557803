/*!

 =========================================================
 * Paper Dashboard PRO React v1.0.0 based on Paper Dashboard 2 Pro - v2.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-2-pro
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Fetch Design globals
@import "fetch/colors.scss";
@import "fetch/typography.scss";
@import "fetch/_variables.scss";

// Variables
@import "theme/fonts";
@import "theme/variables";
@import "theme/mixins";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Loading Skeleton CSS
@import "react-loading-skeleton/dist/skeleton.css";

// Plugins CSS
@import "theme/plugins/plugin-animate-bootstrap-notify";
@import "theme/plugins/plugin-react-table";
@import "theme/plugins/plugin-nouislider";
@import "theme/plugins/plugin-perfect-scrollbar";
@import "theme/plugins/plugin-react-datetime";
@import "theme/plugins/plugin-react-select";

// Core CSS
@import "theme/animations";
@import "theme/buttons";
@import "theme/social-buttons";
@import "theme/animated-buttons";
@import "theme/inputs";
@import "theme/typography";
@import "theme/misc";
@import "theme/misc-extend";
@import "theme/navbar";
@import "theme/dropdown";
@import "theme/alerts";
@import "theme/images";
@import "theme/tables";
@import "theme/footers";
@import "theme/fixed-plugin";

// components
@import "theme/banner";
@import "theme/checkboxes-radio";
@import "theme/progress";
@import "theme/badges";
@import "theme/pagination";
@import "theme/info-areas";
@import "theme/pills";
@import "theme/tabs";
@import "theme/popups";
@import "theme/modals";
@import "theme/carousel";
@import "theme/sidebar-and-main-panel";
@import "theme/timeline";
@import "theme/tutorials";
@import "theme/fileupload";

// forms
@import "theme/forms/forms";

// cards
@import "theme/cards";
@import "theme/cards/card-admin-package-scheduler";
@import "theme/cards/card-batch-details";
@import "theme/cards/card-chart";
@import "theme/cards/card-map";
@import "theme/cards/card-my-shipping-address";
@import "theme/cards/card-background";
@import "theme/cards/card-collapse";
@import "theme/cards/card-contributions";
@import "theme/cards/card-delivery_windows";
@import "theme/cards/card-info-area";
@import "theme/cards/card-lock";
@import "theme/cards/card-my-packages";
@import "theme/cards/card-pricing";
@import "theme/cards/card-profile";
@import "theme/cards/card-signup";
@import "theme/cards/card-stats-mini";
@import "theme/cards/card-stats";
@import "theme/cards/card-subcategories";
@import "theme/cards/card-table";
@import "theme/cards/card-testimonials";
@import "theme/cards/card-tasks";
@import "theme/cards/card-user-details";
@import "theme/cards/card-small-plain";

// example pages and sections
@import "theme/pages";
@import "theme/sections";

@import "theme/responsive";
@import "theme/media-queries";

// common styles
@import "theme/fetch-classes";

// Fetch
@import "fetch/index";
