.single-package {
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  margin: 10px 0 0;
  .first-row {
    .first-row-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 30px;
    }
    border-bottom: solid 1px $fetch-gray-3;
  }
  .single-package__logo {
    width: 40%;
    font-size: 1.5em;
    color: $fetch-gray;
    font-weight: normal;
  }
  .single-package__carrier {
    color: $fetch-gray;
  }
  .second-row {
    padding: 15px 30px;
    font-size: 0.95em;
  }
}
.my-packages-group__package-list {
  position: relative;
}
.my-packages-group__expand-control {
  text-align: center;
  cursor: pointer;
  font-size: 0.95em;
  &:hover {
    opacity: 0.6;
  }
}
.my-packages-group {
  margin: 40px 0 20px;
  h2 {
    color: #fff;
  }
  .my-packages-group__package-list {
    margin-bottom: 20px;
  }
  &.collapsed {
    .single-package:nth-child(1) {
      position: relative;
      z-index: 3;
    }
    .single-package:nth-child(2) {
      position: absolute;
      top: 0;
      bottom: -15px;
      left: 25px;
      right: 25px;
      background: #e8f5fb;
      z-index: 2;
      > * {
        display: none;
      }
    }
    .single-package:nth-child(3) {
      position: absolute;
      top: 0;
      bottom: -30px;
      left: 50px;
      right: 50px;
      background: #c4eaf7;
      > * {
        display: none;
      }
    }
    .single-package:nth-child(n + 4) {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
    /* handle additional margin for absolutely positioned background cards */
    .my-packages-group__package-list {
      margin-bottom: 50px;
    }
    &.children-2 {
      .my-packages-group__package-list {
        margin-bottom: 35px;
      }
    }
    &.children-1 {
      .my-packages-group__package-list {
        margin-bottom: 20px;
      }
    }
  }
}
.status-tracker {
  display: flex;
  justify-content: space-between;
  color: $fds-blue-500;
  position: relative;
}
.status-tracker__status {
  border: 6px solid #00a0d2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  &.active {
    opacity: 1;
    color: $fetch-bright-blue;
    background: white;
    &.status-tracker__delivered {
      background: $fetch-bright-green;
      color: #fff;
    }
  }
}
.status-tracker__line {
  position: absolute;
  height: 3px;
  width: 100%;
  background: $fds-neutral-200;
  top: 50%;
  left: 0;
  z-index: 0;
}
.my-packages-group__schedule {
  display: flex;
  justify-content: center;
}

button.btn.btn-primary-dark {
  margin-top: 30px;
  padding: 20px;
  border-radius: 40px;
  font-size: 1.1em;
  background-color: $fetch-dark-blue;
  text-transform: none;
  width: 80%;
  max-width: 500px;
  svg {
    margin-right: 4px;
  }
  &:hover,
  &:focus {
    background-color: $fetch-dark-blue !important;
    opacity: 0.8;
  }
}
