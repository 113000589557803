.from-check,
.form-check-radio {
  margin-bottom: 12px;
  position: relative;
}

.form-check {
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;

  .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 26px;
    margin-bottom: 0;
    font-size: 1em;
  }
  &.disabled {
    .form-check-label {
      color: $dark-gray;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
}

.form-check-radio {
  margin-left: -3px;

  .form-check-label {
    padding-left: 2rem;
  }
  &.disabled {
    .form-check-label {
      color: $dark-gray;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
