.field-pairs-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  .field-pair-container {
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin: 0px 5px 5px 5px;
    width: 175px;

    .field-pair-title {
      padding: 10px 10px 0px 10px;
    }

    .field-pair-fields-container {
      display: flex;
      flex-direction: column;

      .field-pair-field {
        padding: 0px 10px;
      }
    }
  }
}
