.MenuButton__container {
  & > button {
    margin-right: 0;
  }

  & > button + button.btn.dropdown-toggle {
    margin-left: 0 !important;
  }

  & > .dropdown-menu.show,
  & > .dropdown-menu.show:hover {
    border-radius: 2px;
  }

  & > .dropdown-menu.show > button,
  & > .dropdown-menu.show > button:hover {
    border-radius: 2px;
  }
}
