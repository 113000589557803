@import "../../fetch/colors.scss";

.ModalEditDefaultPay {
    .fds-modal-content {
        padding: 41px 43px;

        .fds-modal-body {
            input {
                width: 100%;
                height: 43px;
            }

            .BlockLabel {
                margin: 8px 0px;
                cursor: default;
            }

            .ButtonRow {
                margin-top: 24px;
                float: right;

                button {
                    margin: 0px;
                    margin-left: 8px;
                }
            }
        }

    }
}