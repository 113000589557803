$fds-font-sizes: (
  "xxs": 11px,
  "xs": 13px,
  "sm": 15px,
  "md": 17px,
  "lg": 18px,
  "xl": 20px,
  "xxl": 30px,
);

$fds-line-heights: (
  xxs: 14px,
  xs: 16px,
  sm: 19px,
  md: 21px,
  lg: 23px,
  xl: 25px,
  xxl: 38px,
);

$fds-font-weights: (
  light: 300,
  normal: 400,
  bold: 600,
  extra-bold: 700,
);

$fds-letter-spacings: (
  normal: 0px,
  wide: 0.18px,
);

$fds-font-size-keys: map-keys(
  $map: $fds-font-sizes,
);

$fds-line-height-keys: map-keys(
  $map: $fds-line-heights,
);

$fds-font-weight-keys: map-keys(
  $map: $fds-font-weights,
);

$fds-letter-spacing-keys: map-keys(
  $map: $fds-letter-spacings,
);

@mixin process-type() {
  @each $key in $fds-font-size-keys {
    .fds-font-size-#{$key} {
      font-size: map-get($fds-font-sizes, $key) !important;
    }
  }

  @each $key in $fds-line-height-keys {
    .fds-line-height-#{$key} {
      line-height: map-get($fds-line-heights, $key) !important;
    }
  }

  @each $key in $fds-font-weight-keys {
    .fds-font-weight-#{$key} {
      font-weight: map-get($fds-font-weights, $key) !important;
    }
  }

  @each $key in $fds-letter-spacing-keys {
    .fds-letter-spacing-#{$key} {
      letter-spacing: map-get($fds-letter-spacings, $key) !important;
    }
  }

  // Source Sans Pro is the only font included in designs
  // The rest of the font stack matches our bootstrap theme/_variables file
  .fds-font-family-sans-serif {
    font-family: "Source Sans Pro", Montserrat, "Helvetica Neue", Arial,
      sans-serif !important;
  }
}

@include process-type();

/* heading */
.fds-heading-400 {
  @extend .fds-font-size-xxl, .fds-line-height-xxl, .fds-font-weight-bold;
}

.fds-heading-300 {
  @extend .fds-font-weight-bold;
  font-size: 24px !important;
  line-height: 30px !important;
}

.fds-heading-200 {
  @extend .fds-font-size-xl, .fds-line-height-xl, .fds-font-weight-bold;
}

.fds-heading-100 {
  @extend .fds-font-size-md, .fds-line-height-md, .fds-font-weight-bold;
}

/* body */
.fds-body-400 {
  @extend .fds-font-size-xxl, .fds-line-height-xxl, .fds-font-weight-normal;
}

.fds-body-300 {
  @extend .fds-font-weight-normal;
  font-size: 24px !important;
  line-height: 30px !important;
}

.fds-body-200 {
  @extend .fds-font-size-xl, .fds-line-height-xl, .fds-font-weight-normal;
}

.fds-body-100 {
  @extend .fds-font-size-md, .fds-line-height-md, .fds-font-weight-normal;
}

.fds-body-75 {
  @extend .fds-font-size-sm, .fds-line-height-sm, .fds-font-weight-normal;
}

.fds-body-50 {
  @extend .fds-font-size-xs, .fds-line-height-xs, .fds-font-weight-normal;
}

.fds-body-25 {
  @extend .fds-font-size-xxs, .fds-line-height-xxs, .fds-font-weight-normal;
}

/* single-line */
.fds-single-line-400 {
  @extend .fds-body-400, .fds-letter-spacing-wide;
}

.fds-single-line-300 {
  @extend .fds-body-300, .fds-letter-spacing-wide;
}

.fds-single-line-200 {
  @extend .fds-body-200, .fds-letter-spacing-wide;
}

.fds-single-line-100 {
  @extend .fds-body-100, .fds-letter-spacing-wide;
}

.fds-single-line-75 {
  @extend .fds-body-75, .fds-letter-spacing-wide;
}

.fds-single-line-50 {
  @extend .fds-body-50, .fds-letter-spacing-wide;
}

.fds-single-line-25 {
  @extend .fds-body-25, .fds-letter-spacing-wide;
}

/* emphasis */
.fds-emphasis-400 {
  @extend .fds-font-size-xxl, .fds-line-height-xxl, .fds-font-weight-bold;
}

.fds-emphasis-300 {
  @extend .fds-font-size-xxl, .fds-line-height-xxl, .fds-font-weight-bold;
  font-size: 24px !important;
  line-height: 30px !important;
}

.fds-emphasis-200 {
  @extend .fds-font-size-xl, .fds-line-height-xl, .fds-font-weight-bold;
}

.fds-emphasis-100 {
  @extend .fds-font-size-md, .fds-line-height-md, .fds-font-weight-bold;
}

.fds-emphasis-75 {
  @extend .fds-font-size-sm, .fds-line-height-sm, .fds-font-weight-bold;
}

.fds-emphasis-50 {
  @extend .fds-font-size-xs, .fds-line-height-xs, .fds-font-weight-bold;
}
