.fds-modal-wrapper.AddPaymentTransactionModal {
    .fds-modal-content {
        /* Allow content to expand since larger than normal modal content */
        width: auto;
        max-width: none;
        min-width: 584px;
    }
}

.AddPaymentTransactionModal__transactionTableWrapper {
    max-height: 300px;
    max-width: 100%;
    overflow: auto;
}