.form-PackageForm__packageBuildingInfoContainer {
  font-size: 0.9em;
  margin-bottom: 10px;
  color: $fetch-black-3;
}

.form-PackageForm__packageBuildingInfoSection {
  padding: 0 7px;
  color: $fetch-black-1;
  &:nth-child(1) {
    padding-left: 0;
  }
}

.form-PackageForm__packageBuildingInfoLabel {
  color: $fetch-gray-1;
  padding-right: 3px;
}
