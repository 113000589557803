.card-user-details,
.card-user-preferences {
  .form-check {
    display: inline-flex;
    flex-direction: row;
    padding-right: 2rem;
  }
  fieldset.form-group {
    margin-bottom: 0;
  }
}
