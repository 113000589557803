.batch-packages-container {
  margin: 20px 0;
  &.included {
    .batch-package-table .rt-tbody .rt-tr {
      background: $fetch-green-bg-light-2;
      &.-odd {
        background: $fetch-green-bg-light-1;
      }
    }
  }
  &.excluded {
    .batch-package-table .rt-tbody .rt-tr {
      background: $fetch-yellow-bg-light-2;
      &.-odd {
        background: $fetch-yellow-bg-light-1;
      }
    }
  }
}
.batch-package-filter-display {
  display: flex;
}
.batch-package-filter-display__container {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  line-height: 1em;
  &.included {
    background: $fetch-green-bg-light-1;
  }
  &.filtered-included {
    background: $fetch-red-bg-light-1;
  }
  &.excluded {
    background: $fetch-yellow-bg-light-1;
  }
}
.batch-package-filter-display__label {
  font-size: 0.8em;
  text-transform: uppercase;
  padding-right: 8px;
}
.batch-package-filter-display__count {
  font-weight: bold;
  color: black;
  font-size: 1.5em;
}
.unlocated-packages-warning {
  color: red;
  table {
    border: solid 2px red;
    color: white;
    thead {
      background: red;
    }
    tbody {
      color: black;
      tr:nth-child(odd) {
        background: $fetch-red-bg-light-1;
      }
      tr:nth-child(even) {
        background: $fetch-red-bg-light-2;
      }
    }
  }
}
