button.btn.btn-sm.form-DeliveryWindowOptionsForm__window {
  font-size: 1.25em;
  width: 90%;
  margin: 5px 0;
}

button.btn.btn-sm.form-RequestPickupForm__requestPickup {
  font-size: 1em;
  width: 90%;
  margin: 5px 0;
  padding: 11px 22px;
}

.form-DeliveryWindowOptionsForm__cutoff {
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.2em;
}

.AdminPackageSchedulerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
