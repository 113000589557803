.ResidentOnboardingPage {
  $address-alignment: 125px;
  $name-aligment: 133px;
  &__fetchAddressText {
    top: 222px;
    left: 180px;
  }

  &__userAddressTextContainer {
    left: 16%;
    bottom: 300px;
    background-color: white;
    border-radius: 20px;
    width: 70%;
  }

  // Page 3 CSS
  &__userCode {
    top: 186px;
    left: 232px;
    font-size: 30px;
  }
  &__userCodeSubtext {
    top: 225px;
    left: 212px;
    width: 180px;
    font-size: 18px;
  }

  &__shippingInfo {
    top: 378px;
    left: $address-alignment;
  }
  &__deliverAddress {
    top: 410px;
    left: $address-alignment;
  }
  &__firstName {
    top: 442px;
    left: $name-aligment;
  }
  &__lastName {
    top: 495px;
    left: $name-aligment;
  }
  &__addressLine1 {
    top: 545px;
    left: $name-aligment;
  }
}

@media screen and (max-width: 500px) {
  .ResidentOnboardingPage {
    $address-alignment: 82px;
    $name-aligment: 88px;
    $name-font-size: 14px;
    &__fetchAddressText {
      top: 222px;
      left: 80px;
    }

    &__userAddressTextContainer {
      bottom: 150px;
    }
    // Page 3 CSS
    &__userCode {
      top: 268px;
      left: 156px;
      font-size: 18px;
    }
    &__userCodeSubtext {
      top: 290px;
      left: 138px;
      width: 120px;
      font-size: 12px;
    }

    &__shippingInfo {
      top: 380px;
      left: $address-alignment;
      font-size: 15px;
    }
    &__deliverAddress {
      top: 402px;
      left: $address-alignment;
      font-size: 15px;
    }
    &__firstName {
      top: 428px;
      left: $name-aligment;
      font-size: $name-font-size;
    }
    &__lastName {
      top: 460px;
      left: $name-aligment;
      font-size: $name-font-size;
    }
    &__addressLine1 {
      top: 492px;
      left: $name-aligment;
      font-size: $name-font-size;
    }
  }
  hr {
    font-size: 14px;
  }
}
