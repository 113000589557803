.fetch-FetchCard__action {
  font-size: 0.9em;
  .FetchIcon svg {
    margin-right: 0 !important;
  }
}
.FetchIcon__message {
  padding-left: 5px;
}
.fetch-Table__clearFiltersButton .FetchIcon__message {
  padding-left: 0;
}
/* Temporarily hide bookmark icon, while we finalize functionality */
#saveBookmark {
  display: none;
}
