.table-form-section {
  align-content: center;
  display: flex;
  width: 100;

  .table-form-container {
    width: 100%;
    position: relative;

    .table-form-button {
      margin-top: -60px;
      position: absolute;
      right: 0;
      top: -50;
    }

    .ReactTable .rt-table,
    .ReactTable .rt-tbody,
    .ReactTable .rt-th,
    .ReactTable .rt-td {
      white-space: unset;

      .has-danger {
        width: 100%;
      }

      .centered-cell {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;

        .form-group {
          display: flex;
          flex-direction: column;
          align-items: center;

          .form-check-input {
            position: static;
            display: block;
            margin: 0px;
          }
        }
      }
    }
  }
}
