.leave-at-door-form {
  h6 {
    margin-top: 20px;
  }
  &.disabled {
    img {
      opacity: 0.5;
    }
  }
}
.ExpressScheduling__enableLeaveAtDoorButton {
  width: 100%;
}
.ExpressScheduling__disableLeaveAtDoorButton {
  width: 100%;
}
