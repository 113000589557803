.ProductTile {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  height: 190px;
  width: 190px;

  .ProductTile__imageContainer {
    height: 80px;

    .ProductTile__image {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .ProductTile__description {
    height: 58px;

    .ProductTile__name {
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .ProductTile__quantityContainer {
    bottom: 7px;
    box-shadow: 0 4px 8px -2px rgba(16, 18, 20, 0.1);
    height: 36px;
    right: 7px;
    transition: width 0.3s ease-in-out;
    width: 36px;

    button {
      padding: 0 5.5px;
    }

    &.ProductTile__quantityContainer--has-quantity {
      right: 4px;
      width: calc(100% - 8px);

      button {
        padding: 0 15px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .ProductTile {
    width: 175px;
  }
}
