.Toastify__toast {
  border: 0;
  border-radius: $border-radius-small;
  color: $white-color;
  position: relative;

  &.Toastify__toast--success {
    background-color: darken($success-color, 5%);
  }

  &.Toastify__toast--warning {
    background-color: darken($warning-color, 5%);
  }

  &.Toastify__toast--info {
    background-color: lighten($info-color, 5%);
  }

  .Toastify__toast-body {
    padding-left: 0.5rem;
  }

  .Toastify__progress-bar {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .close {
    color: $white-color;
    opacity: 0.9;
    text-shadow: none;
    line-height: 0;
    outline: 0;
    font-size: 1.8rem;
    font-weight: 300;

    i.fa,
    i.nc-icon {
      font-size: 14px !important;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  span[data-notify="icon"] {
    font-size: 27px;
    display: block;
    left: 19px;
    position: absolute;
    top: 50%;
    margin-top: -11px;
  }

  button.close {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -13px;
    width: 25px;
    height: 25px;
    padding: 3px;
  }

  .close ~ span {
    display: block;
    max-width: 89%;
  }

  &.alert-with-icon {
    padding-left: 65px;
  }
}

.alert {
  border: 0;
  border-radius: $border-radius-small;
  color: $white-color;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  position: relative;

  &.alert-success {
    background-color: lighten($success-color, 5%);
  }

  &.alert-danger {
    background-color: lighten($danger-color, 5%);
    color: $white-color;
  }

  &.alert-warning {
    background-color: lighten($warning-color, 5%);
  }

  &.alert-info {
    background-color: lighten($info-color, 5%);
  }

  &.alert-primary {
    background-color: lighten($primary-color, 5%);
  }

  .close {
    color: $white-color;
    opacity: 0.9;
    text-shadow: none;
    line-height: 0;
    outline: 0;
    font-size: 1.8rem;
    font-weight: 300;

    i.fa,
    i.nc-icon {
      font-size: 14px !important;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  span[data-notify="icon"] {
    font-size: 27px;
    display: block;
    left: 19px;
    position: absolute;
    top: 50%;
    margin-top: -11px;
  }

  button.close {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -13px;
    width: 25px;
    height: 25px;
    padding: 3px;
  }

  .close ~ span {
    display: block;
    max-width: 89%;
  }

  &.alert-with-icon {
    padding-left: 65px;
  }
}

.sweet-alert {
  h2 {
    font-size: 2.5em !important;
    font-weight: normal !important;
  }
  .text-muted {
    font-size: 1.3em !important;
  }
  a.btn {
    border: none;
    margin: 10px 0;
    text-transform: uppercase;
    font-size: 1em;
    padding: 10px 20px;
    font-weight: 500;
    &.btn-primary {
      @include btn-styles($primary-color, $primary-states-color);
    }
    &.btn-success {
      @include btn-styles($success-color, $success-states-color);
    }
    &.btn-info {
      @include btn-styles($info-color, $info-states-color);
    }
    &.btn-warning {
      @include btn-styles($warning-color, $warning-states-color);
    }
    &.btn-danger {
      @include btn-styles($danger-color, $danger-states-color);
    }
  }
}
