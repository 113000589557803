.DeliveryWindowsCard__windowRow {
  border: 1px solid #dddddd;
  border-radius: 20px;
  margin: 0px 5px 20px 5px;
  padding-bottom: 10px;
}

.DeliveryWindowsCard__deliveryWindowTimes {
  border-radius: 20px 20px 0px 0px; // Round only the top left and top right corners of the window time row
}

.DeliveryWindowsCard__editWindowCutOffButton {
  color: white;
  font-size: 1.25em;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.DeliveryWindowsCard__editBuildingCutOffButton {
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.DeliveryWindowsCard__cutOffRow {
  h3 {
    margin: 0;
    font-size: 1.2em;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: $light-gray;
  margin: 10px 10px 0;
  padding: 10px 20px;
  position: relative;
  &.expanded {
    flex-direction: column;
    align-items: flex-start;
    .DeliveryWindowsCard__buildingsRow {
      border-top: solid 1px #fff;
      margin-top: 20px;
      padding-top: 20px;
      width: 100%;
    }
    .DeliveryWindowsCard__buildingRow:nth-child(n + 4) {
      display: block;
    }
    .DeliveryWindowsCard__buildingCount {
      display: none;
    }
  }
  &.buildingOverride {
    background: #f0cba8;
  }
}

.DeliveryWindowsCard__buildingRow,
.DeliveryWindowsCard__buildingCount {
  background: $fetch-bright-blue;
  margin: 5px;
  border-radius: 4px;
  padding: 10px;
  color: white;
  a {
    color: white;
    cursor: pointer;
  }
}

.DeliveryWindowsCard__buildingCount {
  cursor: zoom-in;
}

.DeliveryWindowsCard__buildingRow:nth-child(n + 4) {
  display: none;
}

.DeliveryWindowsCard__buildingsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.DeliveryWindowsCard__dateNav {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  svg {
    cursor: pointer;
  }
}

.DeliveryWindowsCard__cutOffsRow {
  position: relative;
}

.DeliveryWindowsCard__windowOverrideRow,
.DeliveryWindowsCard__buildingOverrideRow {
  position: absolute;
  background: rgba($color-red-error, 0.9);
  border-radius: 4px;
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: white;
    line-height: 1em;
    margin: 0;
    font-size: 1.2em;
  }
  > * {
    margin: 0 10px;
  }
}
.DeliveryWindowsCard__buildingOverrideRow {
  left: 0;
  right: 0;
}

.DeliveryWindowsCard__alertCutOff {
  color: $fetch-bright-blue;
  font-weight: bold;
}

.DeliveryWindowsCard__overrideExample {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid 1px #999;
  background: #f0cba8;
  margin-right: 5px;
}

.DeliveryWindowsCard__subNotes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
