@import "../../fetch/colors.scss";

$fds-blue-200: map-get(map-get($colors, $key: blue-palette), 200);

.DateBox__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 67px;
  padding-top: 5px;

  &.highlight {
    background-color: $fds-blue-200;
  }
}

.DateBox__day {
  font-weight: 400;
}

.DateBox__date {
  font-weight: 400;
}
