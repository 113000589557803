.fetchVeryLightBlue {
  background-color: lighten($fetch-bright-blue, 15);
  color: white;
}
.brandedDarkBlue {
  color: $fetch-dark-blue;
}
.brandedFontFamily {
  font-family: $font-family-heading;
}
.pageTitle {
  font-family: $font-family-heading;
  font-size: 48px;
  margin: 0;
}
