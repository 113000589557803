.react-joyride__tooltip {
  .welcomeTutorial--step1 {
    h2 {
      margin: 25px 0 0;
    }
  }
  .welcomeTutorial--step2 {
    p {
      font-size: 1.1em;
      margin: 25px 0 0;
    }
  }
}
