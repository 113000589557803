.duotone-white-primary {
  --fa-primary-color: white;
}

.duotone-opaque-secondary {
  --fa-secondary-opacity: 1;
}

.general-circle-background {
  width: auto;
  height: auto;
  padding: 10px;
  background-color: $fds-neutral-100;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-icon-circle-background {
  width: 38px;
  height: 38px;
  background-color: $fds-neutral-100;
  border-radius: 24px;
  display: inline-flex;
  justify-content: center;
}

.fa-ellipsis {
  --fa-primary-color: $fds-blue-900;
  --fa-secondary-color: $fds-blue-900;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
}
