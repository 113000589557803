.FetchDesignFormInputField{
    &__input{
    width: 100%;
    height: 43px;
    background: $fds-neutral-white;
    border: 1px solid $fds-neutral-200; 
    border-radius: 4px;
        &:focus-visible{
            outline: 2px solid $fds-blue-500;
        }
        &:hover{
            border: 1px solid $fds-blue-500;
        }
        &:disabled{
            background: $fds-neutral-100;
            border: 1px solid $fds-neutral-200;
        }
        &.error{
            color: $fds-red-700;
            border: 2px solid $fds-red-600; 
        }
    } 
}
label.FetchDesignFormInputField__label{
    color: $fds-blue-midnight; 
}