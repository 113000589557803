@import "../../fetch/colors.scss";

$fds-blue-700: map-get(map-get($colors, $key: blue-palette), 700);
$fds-neutral-500: map-get(map-get($colors, $key: neutral-palette), 500);

.Tabs__tabs-container li.selected {
  margin-right: 5px;
  border: 1px solid $fds-blue-700;
  border-radius: 2px;
  & a {
    padding: 4px 12px;
    color: $fds-blue-700 !important;
  }
}

.Tabs__tabs-container li.unselected {
  margin-right: 5px;
  border: 1px solid $fds-neutral-500;
  border-radius: 2px;
  & a {
    padding: 4px 12px;
    color: $fds-neutral-500 !important;
  }
}
