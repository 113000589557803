.PackagePickupRequestedCard {
  border-radius: 20px;
  font-family: "Source Sans Pro", "Montserrat", "Helvetica", Arial, sans-serif;
  .status-tracker__status {
    width: 20px;
    height: 20px;
    line-height: 1em;
    background: $fds-blue-500;
    padding: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: #fff;
    &.active {
      width: 35px;
      height: 35px;
      color: white;
      background: $fetch-bright-blue;
      &.status-tracker__delivered {
        background: $fetch-bright-green;
        color: #fff;
        width: 35px;
        height: 35px;
      }
    }
  }
}

.fontFamily {
  font-family: "Source Sans Pro", "Montserrat", "Helvetica", Arial, sans-serif;
}

.trackingFont {
  font-size: 15px;
}

.statusFont {
  font-size: 25px;
}

.packageWidth {
  width: 5%;
}

.cardTop {
  top: 20%;
}

.carrierWidth {
  max-width: 50%;
}

.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  z-index: 999;
}

.left-paddle {
  left: 0;
}

.right-paddle {
  right: 1em;
}

.line {
  width: inherit !important;
  border-top: 2px solid $fetch-yellow !important;
}

.radius {
  border-radius: 0.75rem;
}

.shadow {
  box-shadow: 4px 4px 7px #b3b5b6 !important;
}

.packageTrackingCard {
  background: #edeff0;
}

.chevronIconStyle {
  width: 6px;
  --fa-secondary-opacity: 1;
}

.PackagePickupRequestedCard
  > .PackageDetailsCardList.collapsed
  > .expandCollapse
  > .PackageDetailsCardList__expandControl {
  color: $fds-neutral-800;
}

.PackagePickupRequestedCard > .PackageDetailsCardList.collapsed {
  margin-bottom: 0px;
}
