@import "../../fetch/colors.scss";
.item {
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  background-color: lightgray;
  &.selected {
    background-color: lightblue;
  }
}
