.login-page {
  .card-login {
    border-radius: $border-radius-large;
    padding-bottom: $padding-base-horizontal;
    margin-bottom: 0;
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    border: none;

    label {
      color: #fff;
    }

    .btn-wd {
      min-width: 180px;
    }

    .logo-container {
      width: 65px;
      margin: 0 auto;
      margin-bottom: 55px;

      img {
        width: 100%;
      }
    }

    .input-group:last-child {
      margin-bottom: 40px;
    }

    &.card-plain {
      @include input-coloured-bg(
        $opacity-5,
        $white-color,
        $white-color,
        $transparent-bg,
        $opacity-1,
        $opacity-2
      );

      .input-group-addon,
      .form-group.no-border .input-group-addon,
      .input-group.no-border .input-group-addon {
        color: $opacity-8;
      }
    }
  }

  .link {
    font-size: 10px;
    color: $white-color;
    text-decoration: none;
  }
}

.full-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 0 120px;
  & > .footer {
    position: relative;
    z-index: 4;
  }

  &.section-image {
    position: initial;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;

    .container {
      color: $white-color;
    }
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .pricing-page {
    .description {
      margin-bottom: 65px;
    }
  }

  .register-page {
    & > .container {
      margin-top: 5vh;
    }
    .info-horizontal {
      padding: 0px 0px 20px;
    }

    .info-horizontal {
      text-align: left !important;

      .icon {
        margin-top: 0;

        > i {
          font-size: 2em;
        }

        &.icon-circle {
          width: 65px;
          height: 65px;
          max-width: 65px;
          margin-top: 8px;

          i {
            display: table;
            margin: 0 auto;
            line-height: 3.5;
            font-size: 1.9em;
          }
        }
      }
      .description {
        overflow: hidden;
      }
    }
  }
}

.register-page,
.forgot-password-page,
.recover-password-page,
.login-page,
.lock-page {
  .navbar {
    .navbar-collapse {
      .nav-item .nav-link {
        text-transform: capitalize;
        font-weight: 600;
        color: $white-color;
        &:hover {
          color: $fetch-yellow !important;
        }
        i {
          margin-right: 4px;
        }
      }
    }

    &.navbar-transparent {
      border-bottom: 1px solid transparent;
      margin-top: 25px;
    }

    .navbar-brand {
      color: $white-color !important;
    }
  }
}

.register-page,
.forgot-password-page,
.recover-password-page,
.login-page,
.lock-page {
  width: 100%;
}

.login-page {
  .login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      width: 75%;
    }
  }
}

@media screen and (max-width: 991px) {
  .register-page,
  .forgot-password-page,
  .recover-password-page,
  .login-page,
  .lock-page {
    .navbar {
      &.bg-white {
        .navbar-toggler .navbar-toggler-bar.navbar-kebab {
          background: $default-color !important;
        }
        .navbar-collapse {
          .navbar-nav {
            .nav-item .nav-link {
              color: $default-color !important;
            }
          }
        }
        .navbar-brand {
          color: $default-color !important;
        }
      }

      &.navbar-transparent {
        .navbar-toggle {
          display: none;
        }
        .navbar-toggler {
          .navbar-toggler-bar.navbar-kebab {
            background: $white-color !important;
          }
        }
      }
    }
  }
}

.register-page {
  .fetch-input {
    text-align: left;
  }
  .marketing-blurbs {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}

.recover-password-page,
.forgot-password-page {
  .col {
    display: flex;
    justify-content: center;

    .form {
      width: 400px;

      .card {
        padding: 5px;
      }

      .card-title {
        text-align: center;
        margin: 0px;
      }

      .fetch-input__label {
        color: $fetch-dark-blue;
        font-weight: 600;
        font-size: 0.9em;
      }

      .instructions {
        text-align: center;
        margin-bottom: 5px;
        font-size: 0.95em;
        color: $fetch-gray;
      }

      form {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.reset-password-page,
.address-setup-page,
.loading-page {
  background-color: $fetch-bg-blue1;

  #root {
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.verify-contact-options-page {
  background-color: $fetch-bg-blue1;

  #root {
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
}

.LoadingPage__spinner {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.DeliveryPreferences {
  display: flex;
  flex-direction: column;

  .DeliveryPreferences__optionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .DeliveryPreferences__option {
      padding: 0px;
      width: calc(50% - 5px);
      button {
        width: 100%;
        margin-top: 0;
        &.btn-selected {
          background-color: $fetch-bright-blue !important;
        }
        &.btn-not-selected {
          opacity: 0.5;
        }
        &:hover {
          background-color: $fetch-dark-blue !important;
          opacity: 1;
        }
      }
    }
  }

  .DeliveryPreferences__setByBuilding {
    padding-top: 5px;
  }
}

.DeliveryPreferences__leaveWaiverCheckbox {
  display: flex;
  flex-direction: column;

  .fetch-input__label {
    padding: 1px 0px 0px 20px;
  }

  .form-check-input {
    display: block;
    margin: 6px 5px 0px 0px;
  }
}

.DeliveryPreferences__explanation {
  margin-top: 10px;
}

.TermsConditionsPolicy {
  display: flex;
  flex-direction: column;
  .TermsConditionsPolicy__label {
    padding: 1px 0px 0px 20px;
    font-weight: 600;
    color: $fetch-dark-blue;
    a {
      color: $fetch-dark-blue;
      text-decoration: underline;
    }
  }

  .form-check-input {
    display: block;
    margin: 6px 5px 0px 0px;
  }
}

.RegistrationHelp {
  display: flex;
  flex-direction: column;
  border: 1px solid $fetch-yellow;
  border-radius: 3px;

  .RegistrationHelp__title {
    color: white;
    background-color: $fetch-yellow;
    padding: 5px;
  }

  .RegistrationHelp__message {
    padding: 5px 10px;
  }
}

/* My Packages Page */
.MyPackageHistory {
  color: $fds-blue-500;
  .empty-day {
    text-align: center;
    padding-top: 40px;
    .empty-day__icon {
      width: 50%;
    }
  }
  h2 {
    text-align: center;
    font-size: 2em;
    margin: 0.6em 0 0.1em;
  }
}
.my-packages-page .content {
  background-color: $fds-blue-500;
  div.greeting {
    filter: contrast(120%);
    color: white;
  }
  .empty-day {
    text-align: center;
    padding-top: 40px;
    .empty-day__icon {
      width: 50%;
    }
  }
  h2 {
    text-align: center;
    font-size: 2em;
    margin: 0.6em 0 0.1em;
    filter: contrast(120%);
  }
  div.subhead {
    color: white;
    filter: contrast(120%);
    text-align: center;
    button {
      color: $fds-blue-500;
      font-weight: 600;
      border: none;
      outline: none;
    }
    margin-bottom: 20px;
    .subhead-link {
      color: $fds-blue-500;
    }
  }
  p {
    margin: 0;
  }
}

.my-packages-group .subhead-options {
  color: $fill-font-color !important;
}

.my-batches-page,
.my-batch-detail-page {
  .content {
    margin-top: 65px;
    margin-bottom: 15px;
  }

  .MyBatchesCard {
    background-color: lighten($fetch-gray-3, 5%);

    .card-title {
      margin: 5px 0px;
      margin-left: -15px;
    }

    .card-body {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 0px;
    }

    .badge {
      margin-bottom: 0px;
    }
  }

  .DriverBatchWindowCard {
    background-color: lighten($fetch-gray-3, 5%);

    .card-title {
      margin: 0px;
    }

    .card-body {
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 0px;
    }

    .badge {
      margin-bottom: 0px;
    }
  }

  .DriverBatchDetailCard {
    background-color: lighten($fetch-gray-3, 5%);

    .card-body {
      padding: 0px;
    }

    .DriverBatchDetailCard__header {
      cursor: pointer;
    }

    .DriverBatchStopOverviewCard {
      margin-bottom: 0px;

      @mixin highlight($r, $g, $b, $opacity) {
        -moz-box-shadow: 0px 0px 5px rgba($r, $g, $b, $opacity);
        -webkit-box-shadow: 0px 0px 5px rgba($r, $g, $b, $opacity);
        box-shadow: 0px 0px 5px rgba($r, $g, $b, $opacity);
      }

      &.OUT_FOR_DELIVERY {
        @include highlight(16, 154, 197, 0.25);

        &:hover {
          @include highlight(16, 154, 197, 1);
        }
      }
      &.BATCHED {
        @include highlight(16, 154, 197, 0.25);

        &:hover {
          @include highlight(16, 154, 197, 1);
        }
      }
      &.EXCEPTION_TRIED_ONCE {
        @include highlight(255, 205, 68, 0.25);

        &:hover {
          @include highlight(255, 205, 68, 1);
        }
      }
      &.EXCEPTION_TRIED_TWICE {
        @include highlight(216, 76, 65, 0.25);

        &:hover {
          @include highlight(216, 76, 65, 1);
        }
      }
      &.DELIVERED {
        @include highlight(81, 156, 108, 0.25);

        &:hover {
          @include highlight(81, 156, 108, 1);
        }
      }

      .DriverBatchStopOverviewCard__packageDeliveryPreference {
        &.LEAVE {
          color: rgb(81, 156, 108);
        }
        &.DO_NOT_LEAVE {
          color: rgb(216, 76, 65);
        }
      }

      .DriverBatchStopOverviewCard__chevron {
        cursor: pointer;
      }
    }
  }

  .DeliveryDetailCard {
    .card-body {
      padding-bottom: 0px;
    }
  }
}
.LoggingDashboardPage__cardInfo {
  background-color: #eeeff0;
  .card-body {
    .DashboardStats__facility-shortcode__row {
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 28px;
    }
    .DashboardStats__row {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .DashboardStats__item {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 25%;

      span:nth-child(1) {
        font-weight: 600;
        font-size: 28px;
      }

      span:nth-child(2) {
        font-weight: 400;
        font-size: 18px;
      }
    }
    .DashboardStats__refresh-button {
      width: 150px;
      margin-bottom: 0px;
    }
    .DashboardStats__refresh-button--row {
      display: flex;
      justify-content: flex-end;
    }
    .DashboardStats__refresh-button--icon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  &.loading.card {
    .loading-card-overlay {
      background: inherit;
      .spinner-border {
        width: 3em;
        height: 3em;
      }
    }
    height: 100px;
    background-color: #eeeff0;
    padding: 10px 0 10px 0;
  }
}
/* Background */
$imgPath: "~../img/";
.cloud {
  background: #fff;
  background: url("#{$imgPath}cloud-blue-base.svg") 0 0;
  background-size: 92px 50px;
  width: 92px;
  position: relative;
  height: 50px;
}
/*Cloud Animations*/

/* KEYFRAMES */

@-webkit-keyframes animateCloud {
  0% {
    margin-left: -200px;
  }
  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: -200px;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -200px;
  }
  100% {
    margin-left: 100%;
  }
}

@-webkit-keyframes animateCloudLeft {
  0% {
    margin-left: 101%;
  }
  100% {
    margin-left: -100px;
  }
}

@-moz-keyframes animateCloudLeft {
  0% {
    margin-left: 101%;
  }
  100% {
    margin-left: -100px;
  }
}

@keyframes animateCloudLeft {
  0% {
    margin-left: 101%;
  }
  100% {
    margin-left: -100px;
  }
}
/* ANIMATIONS */

.x1 {
  -webkit-animation: animateCloud 220s linear infinite;
  -moz-animation: animateCloud 220s linear infinite;
  animation: animateCloud 220s linear infinite;

  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  transform: scale(0.95);
  opacity: 0.35;
}

.x2 {
  -webkit-animation: animateCloud 160s linear infinite;
  -moz-animation: animateCloud 610s linear infinite;
  animation: animateCloud 160s linear infinite;

  -webkit-transform: scale(0.6) translate(0, -40px);
  -moz-transform: scale(0.6) translate(0, -40px);
  transform: scale(0.6) translate(0, -40px);
  opacity: 0.8;
}

.x3 {
  -webkit-animation: animateCloud 170s linear infinite;
  -moz-animation: animateCloud 170s linear infinite;
  animation: animateCloud 170s linear infinite;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.8;
}

.x4 {
  -webkit-animation: animateCloud 258s linear infinite;
  -moz-animation: animateCloud 258s linear infinite;
  animation: animateCloud 258s linear infinite;

  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0.3;
}

.x5 {
  -webkit-animation: animateCloud 165s linear infinite;
  -moz-animation: animateCloud 165s linear infinite;
  animation: animateCloud 65s linear infinite;

  -webkit-transform: scale(0.85) translate(0, -140px);
  -moz-transform: scale(0.85) translate(0, -140px);
  transform: scale(0.85) translate(0, -140px);
  opacity: 0.6;
}
.x6 {
  -webkit-animation: animateCloudLeft 175s linear infinite;
  -moz-animation: animateCloudLeft 175s linear infinite;
  animation: animateCloudLeft 175s linear infinite;

  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  transform: scale(0.95);
  opacity: 0.5;
}

.x7 {
  -webkit-animation: animateCloudLeft 120s linear infinite;
  -moz-animation: animateCloudLeft 120s linear infinite;
  animation: animateCloudLeft 120s linear infinite;

  -webkit-transform: scale(0.6) translate(-200px, -240px);
  -moz-transform: scale(0.6) translate(-200px, -240px);
  transform: scale(0.6) translate(-200px, -240px);
  opacity: 0.4;
}

.x8 {
  -webkit-animation: animateCloudLeft 170s linear infinite;
  -moz-animation: animateCloudLeft 170s linear infinite;
  animation: animateCloudLeft 170s linear infinite;

  -webkit-transform: scale(0.8) translate(0, -340px);
  -moz-transform: scale(0.8) translate(0, -340px);
  transform: scale(0.8) translate(0, -340px);
  opacity: 0.3;
}

.x9 {
  -webkit-animation: animateCloudLeft 220s linear infinite;
  -moz-animation: animateCloudLeft 220s linear infinite;
  animation: animateCloudLeft 220s linear infinite;

  -webkit-transform: scale(0.7) translate(-140px, -80px);
  -moz-transform: scale(0.7) translate(-140px, -80px);
  transform: scale(0.7) translate(-140px, -80px);
  opacity: 0.6;
}

.x10 {
  -webkit-animation: animateCloudLeft 145s linear infinite;
  -moz-animation: animateCloudLeft 145s linear infinite;
  animation: animateCloudLeft 145s linear infinite;

  -webkit-transform: scale(0.85) translate(160px, -20px);
  -moz-transform: scale(0.85) translate(160px, -20px);
  transform: scale(0.85) translate(160px, -20px);
  opacity: 0.2;
}

.cloud-wrapper {
  bottom: 0;
  left: 0;
  padding-top: 50px;
  position: absolute;
  width: 100%;
  top: 0;
  overflow: hidden;
  z-index: 1;
}

// Hot Air Balloon Animation

.lift-off {
  position: absolute;
  top: 350px;
  left: 90%;
  animation: liftOff 80s infinite -0.5s cubic-bezier(0.2, 0.4, 0.6, 1);
  transform-origin: top center;
}
.hot-air-balloon {
  background: url("#{$imgPath}hot-air-balloon-hero.svg") 0 0;
  background-size: 60px 80px;
  width: 60px;
  position: relative;
  height: 80px;
}
.hot-air-balloon-container {
  width: 60px;
  position: absolute;
  top: calc(100% - 100px);
  left: 80%;
  transform: translate(-50%, -50%);

  .rotate {
    animation: rotate 3s infinite cubic-bezier(0.5, 0, 0.5, 1);
    transform-origin: 95% 5%;
  }

  .translateX {
    animation: translateX 3s infinite -0.5s cubic-bezier(0.5, 0, 0.5, 1);
    transform-origin: top center;
  }
  .translateY {
    animation: translateY 1.5s infinite -1.25s cubic-bezier(0.5, 0, 0.5, 1);
    transform-origin: top center;
  }
  @keyframes rotate {
    0%,
    100% {
      // transform: rotate(-10deg);
      transform: rotate(-5deg);
    }
    50% {
      // transform: rotate(20deg);
      transform: rotate(10deg);
    }
  }

  @keyframes translateX {
    0%,
    100% {
      // transform: translateX(40%);
      transform: translateX(20%);
    }
    50% {
      // transform: translateX(-20%);
      transform: translateX(-10%);
    }
  }

  @keyframes liftOff {
    0%,
    100% {
      transform: translateY(0);
    }

    45%,
    65% {
      // transform: translateX(-20%);
      transform: translateY(calc(-105vh));
    }
  }

  @keyframes translateY {
    0%,
    100% {
      transform: translateY(0) scaleY(0.95);
    }
    50% {
      transform: translateY(5%) scaleY(1);
    }
  }
}
/* End Background */

/* Verify Contact Options */
.VerifyContactOptionsPage__skip {
  text-align: center;
  width: 100%;
}
/* End Verify Contact Options */

.full-height-cards {
  .card {
    height: calc(100% - 20px);
  }
}

.DeliverStopCard {
  margin: -12px -15px -45px -15px;

  .DeliverStopCard__deliveryMethodIcon {
    border: 2px solid black;
    border-radius: 25px;
    width: max-content;
  }

  .DeliverStopCard__stopDetails {
    border-radius: 0 0 10% 10%;
  }

  .DeliverStopCard__photoConfirmationContainer {
    border-radius: 0 0 15px 15px;
  }
}

.fetch-Webcam {
  position: relative;

  .fetch-Webcam__captureContainer {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
  }
}

.fetch-Signature {
  width: 100vw;
  height: 100%;

  .fetch-Signature__accept {
    height: 60px;
    width: 60px;
    position: absolute;
    bottom: 53px;
    right: 5px;
    font-size: 0.8rem;
  }

  .fetch-Signature__placeholder {
    position: absolute;
    bottom: 50px;
    pointer-events: none;
  }
}

.font-family-serif {
  font-family: serif;
}
