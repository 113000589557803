.fetch-banner {
  background: #2eb9e4;
  border-bottom: solid 5px #fecc51;
  position: relative;
  height: 50px;
  box-sizing: border-box;
  a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
  .fetch-banner__message {
    color: #fff;
    text-align: center;
  }
}

body.banner-visible {
  .navbar-auth {
    top: 50px;
  }
  .wrapper-full-page .full-page {
    padding-top: 150px;
  }
  .sidebar {
    top: 50px;
  }
  &.loading-page {
    .fetch-banner {
      display: none;
    }
  }
}
