.OverSizePackageDetailsCard {
  border-radius: 20px;
  font-family: "Source Sans Pro", "Montserrat", "Helvetica", Arial, sans-serif;
  .status-tracker__status {
    border: 6px solid white;
  }
  .my-packages-group__status-tracker {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.OverSizePackageDetailsCard .PackageDetailsCardList__expandControl {
  color: $fds-blue-midnight;
}

.OverSizePackageDetailsCard__packages > .PackageDetailsCardList {
  margin-left: 10%;
  margin-right: 10%;
}

.fontFamily {
  font-family: "Source Sans Pro", "Montserrat", "Helvetica", Arial, sans-serif;
}

.trackingFont {
  font-size: 15px;
}

.statusFont {
  font-size: 25px;
}

.packageWidth {
  width: 5%;
}

.cardTop {
  top: 20%;
}

.carrierWidth {
  max-width: 50%;
}

.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  z-index: 999;
}

.left-paddle {
  left: 0;
}

.right-paddle {
  right: 1em;
}

.line {
  width: inherit !important;
  border-top: 2px solid $fetch-yellow !important;
}

.radius {
  border-radius: 0.75rem;
}

.shadow {
  box-shadow: 4px 4px 7px #b3b5b6 !important;
}

.packageTrackingCard {
  background: #edeff0;
}
