.card-signup {
  .header {
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px 0;
  }
  .text-divider {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
  .content {
    padding: 0px 30px;
  }

  .form-check {
    margin-top: 20px;

    label {
      margin-left: 7px;
      padding-left: 38px;
    }
  }

  .social-line {
    margin-top: 20px;
    text-align: center;

    .btn.btn-icon,
    .btn.btn-icon .btn-icon {
      margin-left: 5px;
      margin-right: 5px;
      box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .card-footer {
    margin-bottom: 10px;
    margin-top: 24px;
  }
}
