.fds-border-rounded {
  border-radius: 6px;
}

.fds-clickable {
  cursor: pointer;
}

button.btn.fds-pill-button {
  border-radius: 28px;
  padding: 14px 34px 14px 34px;
}
